import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import { PageHeader02, BlogDetails02 } from "gatsby-theme-container";

const BlogDetailsTemplate = ({ data }) => (
    <Layout>
        <SEO pageName="Blog Left Sidebar Details" isBlogPost />
        <HeaderOne hasBorder data={{ ...data?.header, ...data?.navigation }} />
        <PageHeader02 />
        <main className="main-content">
            <BlogDetails02
                data={{
                    ...data.article,
                    siteUrl: data.site.siteMetadata.siteUrl,
                }}
            />
        </main>
        <FooterOne data={{ ...data?.footer, ...data?.contact }} />
    </Layout>
);

export const query = graphql`
    query BlogDetailsLeftTemplateQuery($slug: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        article(slug: { eq: $slug }) {
            ...ArticleDetails
        }
    }
`;
BlogDetailsTemplate.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                siteUrl: PropTypes.string,
            }),
        }),
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        article: PropTypes.shape({}),
    }),
};

export default BlogDetailsTemplate;
